import React from 'react';

import { Value } from 'models/Facets/Value';
import { Swatch } from 'models/Facets';
import { useHawkConfig } from 'components/ConfigProvider';
import * as Constants from 'util/Constants';

export interface SwatchItemProps {
	swatchValue: Value;
	facetSwatch: Swatch;
	isNegated: boolean;
	isSelected: boolean;
	isColor: boolean;
	onSwatchSelected(facetValue: string, isNegated: boolean): void;
}

/**
 * Gets the absolute URL of the swatch's image resource.
 */
function getSwatchUrl(item: SwatchItemProps) {
	// eslint-disable-next-line
	const { config } = useHawkConfig();

	let suffix = !item.facetSwatch.AssetUrl ? item.facetSwatch.AssetName : item.facetSwatch.AssetUrl;

	if (suffix.length === 0 || /^https?:\/\//.test(suffix)) {
		// If our "suffix" is an absolute url or empty it isn't meaningful to prepend anything to it.
		return suffix;
	}

	// Suffix must have a prepended slash
	if (suffix[0] !== '/') {
		suffix = '/' + suffix;
	}

	// Prefix shoudn't have an appended slash
	// Technically speaking this is legal eg "https://www.example.com/test//assets/1232/red.gif" but it is more likely than not, an error.
	const dashboardUrl = config.dashboardUrl || Constants.defaultDashboardUrl;
	const prefix =
		dashboardUrl[dashboardUrl.length - 1] === '/'
			? dashboardUrl.substring(0, dashboardUrl.length - 1)
			: dashboardUrl;

	return prefix + suffix;
}

function SwatchItem(item: SwatchItemProps) {
	const facetValue = item.swatchValue.Value || '';

	// facets can be selected or negated, so explicitly check that the facet is not selected
	const swatchUrl = getSwatchUrl(item);

	const colorSwatchStyle = {
		backgroundColor: item.facetSwatch.Color,
	};

	const listItemClassNames =
		'hawk-facet-rail__swatch-list-item' +
		(item.isSelected ? ' hawkFacet-active' : '') +
		(item.isNegated ? ' hawkFacet-negative' : '');

	return (
		<li key={item.facetSwatch.Value} className={listItemClassNames}>
			<button
				onClick={() => item.onSwatchSelected(facetValue, false)}
				className="hawk-styleSwatch"
				aria-pressed={item.isSelected}
			>
				<span className="hawk-selectionInner">
					{item.isColor ? (
						<span className="hawk-swatchColor" style={colorSwatchStyle} title={item.facetSwatch.Value} />
					) : (
						<img className="hawk-selectionInner__image" src={swatchUrl} alt={item.facetSwatch.Value} />
					)}
				</span>
			</button>
			<button className="hawk-negativeIcon" onClick={() => item.onSwatchSelected(facetValue, true)}>
				<i className="hawkIcon-blocked" />
			</button>
		</li>
	);
}

export default SwatchItem;
