import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * X SVG
 *
 * @returns
 */
function XSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 32 32"
			className={classnames('roc-icon roc-icon-x', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path d="M16.058 10.971l9.825-9.825c1.373-1.373 3.598-1.373 4.971 0s1.373 3.598 0 4.971l-9.825 9.825 9.942 9.941c1.373 1.373 1.373 3.598 0 4.971s-3.598 1.373-4.971 0l-9.942-9.942-10.058 10.058c-1.373 1.373-3.598 1.373-4.971 0s-1.373-3.598 0-4.971l10.058-10.058-9.941-9.941c-1.373-1.373-1.373-3.598 0-4.971s3.598-1.373 4.971 0l9.941 9.941z" />
		</svg>
	);
}

export default XSVG;
