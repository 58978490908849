import { AxiosError } from 'axios';
import RocHawkConfig from 'features/search/RocHawkConfig';
import AxiosHelper from 'services/AxiosHelper';
import { getCookie, setCookie } from 'shared/components/hawksearch/helpers/Cookies';
import { ExtractEventParameters } from 'shared/events';
//#region Commerce
import { CommercePageType, CommerceSuggestType, trackCommerce } from '../commerce/hawksearch/tracking-commerce';
//#endregion

export interface HawkSearch {
	ClientGuid: string;
	Context: ContextDictionary;
	CustomContext: ContextDictionary;
	TrackingUrl: string | null;
	initCustomEvents?: () => void;
}

declare global {
	interface Window {
		HawkSearch: HawkSearch;
	}
}

export enum EventTrackingType {
	pageLoad = 1,
	search = 2,
	click = 3,
	bannerClick = 7,
	bannerImpression = 8,
	recommendationClick = 10,
	autoCompleteClick = 11,
}

/**
 * The page type
 */
export enum PageType {
	/**
	 * Landing Page
	 */
	landing = 2,
	/**
	 * All other Page Types
	 */
	custom = 5,
}

export enum SuggestType {
	PopularSearches = 1,
	TopContentMatches = 4,
}

export enum SearchType {
	Initial = 1,
	Refinement = 2,
}

type HawkEvents =
	| ({ name: 'pageload' } & TrackPageLoadArgs)
	| ({ name: 'searchtracking' } & TrackSearchTrackingArgs)
	| ({ name: 'click' } & TrackClickArgs)
	| ({ name: 'bannerclick' } & TrackBannerClickArgs)
	| ({ name: 'bannerimpression' } & TrackBannerImpressionArgs)
	| ({ name: 'autocompleteclick' } & TrackAutoCompleteClickArgs)
	| ({ name: 'recommendationclick' } & TrackRecommendationClickArgs);

export type HawkEventType = HawkEvents['name'];

function track<T extends HawkEventType>(eventName: T, args: ExtractEventParameters<HawkEvents, T>) {
	switch (eventName.toLocaleLowerCase()) {
		case 'pageload':
			//HawkSearch.Context.add("uniqueid", "123456789");
			//HawkSearch.Tracking.track('pageload',{pageType: "item"});
			return trackPageLoad((args as unknown) as TrackPageLoadArgs);
		case 'searchtracking':
			//HawkSearch.Tracking.track("searchtracking", {trackingId:"a9bd6e50-e434-45b9-9f66-489eca07ad0a", typeId: HawkSearch.Tracking.SearchType.Initial});
			//HawkSearch.Tracking.track("searchtracking", {trackingId:"a9bd6e50-e434-45b9-9f66-489eca07ad0a", typeId: HawkSearch.Tracking.SearchType.Refinement});
			return trackSearchTracking((args as unknown) as TrackSearchTrackingArgs);
		case 'click':
			//HawkSearch.Tracking.track('click',{event: e, uniqueId: "33333", trackingId: "75a0801a-a93c-4bcb-81f1-f4b011f616e3"});
			return trackClick((args as unknown) as TrackClickArgs);
		case 'bannerclick':
			//HawkSearch.Tracking.track('bannerclick',{bannerId: 1, campaignId: 2, trackingId:"2d652a1e-2e05-4414-9d76-51979109f724"});
			return trackBannerClick((args as unknown) as TrackBannerClickArgs);
		case 'bannerimpression':
			//HawkSearch.Tracking.track('bannerimpression',{bannerId: "2", campaignId: "2", trackingId:"2d652a1e-2e05-4414-9d76-51979109f724"});
			return trackBannerImpression((args as unknown) as TrackBannerImpressionArgs);
		case 'autocompleteclick':
			//HawkSearch.Tracking.track('autocompleteclick',{keyword: "test", suggestType: HawkSearch.Tracking.SuggestType.PopularSearches, name:"tester", url:"/test"});
			return trackAutoCompleteClick((args as unknown) as TrackAutoCompleteClickArgs);
		case 'recommendationclick':
			//HawkSearch.Tracking.track('recommendationclick',{uniqueId: "223222", itemIndex: "222", widgetGuid:"2d652a1e-2e05-4414-9d76-51979109f724", requestId:"2d652a1e-2e05-4414-9d76-51979109f724"});
			return trackRecommendationClick((args as unknown) as TrackRecommendationClickArgs);
	}
}

function getVisitorExpiry() {
	const d = new Date();
	// 1 year
	d.setTime(d.getTime() + 360 * 24 * 60 * 60 * 1000);
	return d.toUTCString();
}

function getVisitExpiry() {
	const d = new Date();
	// 4 hours
	d.setTime(d.getTime() + 4 * 60 * 60 * 1000);
	return d.toUTCString();
}

function createGuid() {
	const s: string[] = [];
	const hexDigits = '0123456789abcdef';
	for (let i = 0; i < 36; i++) {
		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
	}
	s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
	s[19] = hexDigits.substr(((s[19] as any) & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
	s[8] = s[13] = s[18] = s[23] = '-';

	return s.join('');
}

type TrackPageLoadArgs = {
	/**
	 * The page type
	 */
	pageType:
		| PageType
		// #region Commerce
		| CommercePageType;
	// #endregion

	/**
	 * Unique identifier of item that was clicked
	 */
	uniqueId?: string;
};

function trackPageLoad({ pageType, uniqueId }: TrackPageLoadArgs) {
	const { clientHeight, clientWidth } = document.documentElement;

	const payload = {
		EventType: EventTrackingType.pageLoad,
		EventData: preparePayload({
			PageTypeId: pageType,
			RequestPath: window.location.pathname,
			Qs: window.location.search,
			ViewportHeight: clientHeight,
			ViewportWidth: clientWidth,
			UniqueId: uniqueId,
		}),
	};

	makeRequest(payload);
}

type TrackSearchTrackingArgs = {
	/**
	 * The ‘TrackingId’ object that is passed in this function is returned in the Hawksearch response
	 */
	trackingId: string;
	/**
	 * The type of search that is performed
	 */
	typeId: SearchType;
};

function trackSearchTracking({ trackingId, typeId }: TrackSearchTrackingArgs) {
	if (typeId == SearchType.Initial) {
		setCookie('hawk_query_id', createGuid());
	}

	const queryId = getCookie('hawk_query_id');
	const { clientHeight, clientWidth } = document.documentElement;
	const payload = {
		EventType: EventTrackingType.search,
		EventData: preparePayload({
			QueryId: queryId,
			TrackingId: trackingId,
			TypeId: typeId,
			ViewportHeight: clientHeight,
			ViewportWidth: clientWidth,
		}),
	};

	makeRequest(payload);
}

type TrackClickArgs = {
	/**
	 * Unique identifier of item that was clicked
	 */
	uniqueId: string;
	/**
	 * This is the TrackingId that was passed back in the search response
	 */
	trackingId: string;
	/**
	 * Target URL that clicked element points to
	 */
	url: string;
};

function trackClick({ uniqueId, trackingId, url }: TrackClickArgs) {
	const { clientHeight, clientWidth } = document.documentElement;

	const payload = {
		EventType: EventTrackingType.click,
		EventData: preparePayload({
			Url: url,
			Qs: window.location.search,
			RequestPath: window.location.pathname,
			TrackingId: trackingId,
			UniqueId: uniqueId,
			ViewportHeight: clientHeight,
			ViewportWidth: clientWidth,
		}),
	};

	makeRequest(payload);
}

type TrackBannerClickArgs = {
	/**
	 * The identifier of corresponding banner
	 */
	bannerId: string;
	/**
	 * The identifier of corresponding campaign (optional)
	 */
	campaignId: string;
	/**
	 * The ‘TrackingId’ object that is passed in this function is returned in the Hawksearch response
	 */
	trackingId: string;
};

function trackBannerClick({ bannerId, trackingId, campaignId }: TrackBannerClickArgs) {
	const payload = {
		EventType: EventTrackingType.bannerClick,
		EventData: preparePayload({
			CampaignId: campaignId,
			BannerId: bannerId,
			TrackingId: trackingId,
		}),
	};

	makeRequest(payload);
}

type TrackBannerImpressionArgs = TrackBannerClickArgs;

function trackBannerImpression({ bannerId, campaignId, trackingId }: TrackBannerImpressionArgs) {
	const payload = {
		EventType: EventTrackingType.bannerImpression,
		EventData: preparePayload({
			CampaignId: campaignId,
			BannerId: bannerId,
			TrackingId: trackingId,
		}),
	};
	makeRequest(payload);
}

type TrackAutoCompleteClickArgs = {
	/**
	 * Selected keyword used as a autocomplete query
	 */
	keyword: string;
	/**
	 * The type of autocomplete results type
	 */
	suggestType?: SuggestType | CommerceSuggestType;
	/**
	 * Text value associated with the autocomplete item clicked
	 */
	itemName: string;
	/**
	 * The target URL of clicked autocomplete item
	 */
	url: string;
};

function trackAutoCompleteClick({ keyword, suggestType, itemName, url }: TrackAutoCompleteClickArgs) {
	const payload = {
		EventType: EventTrackingType.autoCompleteClick,
		EventData: preparePayload({
			Keyword: keyword,
			Name: itemName,
			SuggestType: suggestType,
			Url: url,
		}),
	};

	makeRequest(payload);
}

type TrackRecommendationClickArgs = {
	/**
	 * The unique identifier of the recommendation widget that displayed clicked item. This should correspond to the widget GUID generated for each widget individually in Hawksearch dashboard
	 */
	widgetGuid: string;
	/**
	 * Represents the unique identifier of a product. This should correspond to the value of the field set up as the primary key in the fields section of Hawksearch dashboard.
	 */
	uniqueId: string;
	/**
	 * The number of the clicked recommendation item on the displayed widget.
	 */
	itemIndex: number;
	/**
	 * The ‘RequestId’ object that is passed in this function is returned in the recommendation widget response
	 */
	requestId: string;
};

function trackRecommendationClick({ widgetGuid, uniqueId, itemIndex, requestId }: TrackRecommendationClickArgs) {
	const payload = {
		EventType: EventTrackingType.recommendationClick,
		EventData: preparePayload({
			ItemIndex: itemIndex,
			RequestId: requestId,
			UniqueId: uniqueId,
			WidgetGuid: widgetGuid,
		}),
	};

	makeRequest(payload);
}

// #region HCL
function safeBtoa(input: string): string {
	// Encode the string into UTF-8 and then to base64
	const utf8Bytes = new TextEncoder().encode(input);
	return btoa(String.fromCharCode(...utf8Bytes));
}
// #endregion

export function preparePayload(payload: any) {
	// #region HCL
	return safeBtoa(JSON.stringify(payload));
	// #endregion
}

export function makeRequest(data) {
	if (!HawkSearch.TrackingUrl) {
		console.warn(
			"Unable to send tracking event to HawkSearch because site's configuration is missing tracking URL.",
		);
		return;
	}

	let visitId = getCookie('hawk_visit_id');
	let visitorId = getCookie('hawk_visitor_id');
	const isNewVisitor = !visitId || !visitorId;

	if (!visitId) {
		setCookie('hawk_visit_id', createGuid(), getVisitExpiry());
		visitId = getCookie('hawk_visit_id');
	}

	if (!visitorId) {
		setCookie('hawk_visitor_id', createGuid(), getVisitorExpiry());
		visitorId = getCookie('hawk_visitor_id');
	}

	if (isNewVisitor) {
		AxiosHelper.post('/ajax/hawksearch/identify', {
			visitId: visitId,
			visitorId: visitorId,
		});
	}

	const payload = Object.assign(
		{
			ClientGuid: HawkSearch.ClientGuid,
			VisitId: visitId,
			VisitorId: visitorId,
			TrackingProperties: HawkSearch.Context,
			CustomDictionary: HawkSearch.CustomContext,
		},
		data,
	);

	// #region HCL
	AxiosHelper.post<any>('/ajax/hawksearch/trackevent', { request: payload })
		// #endregion
		.then((response) => {
			console.log('Success:', response.statusText);
		})
		.catch((error: AxiosError) => {
			console.error('Error:', error);
		});
}

// TODO: add comments about what this is for? i think it has to do with timing between hawksearch.js and this script?
function init() {
	if (window.HawkSearch && typeof window.HawkSearch.initCustomEvents === 'function') {
		window.HawkSearch.initCustomEvents();
	}
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
	setTimeout(init);
} else {
	document.addEventListener('DOMContentLoaded', init);
}

class ContextDictionary {
	add(key: string, value: any) {
		this[key] = value;
	}

	clear() {
		for (const key in this) {
			this.remove(key);
		}
	}

	remove(key: string) {
		if (this.hasOwnProperty(key)) {
			delete this[key];
		}
	}

	push(key: string, value: any) {
		if (this.hasOwnProperty(key)) {
			const existingValue = this[key];
			this[key] = `${existingValue},${value}`;
		} else {
			this.add(key, value);
		}
	}
}

const Context = new ContextDictionary();
const CustomContext = new ContextDictionary();

// initialize HawkSearch with values from RocHawkConfig
if (!window.HawkSearch) {
	window.HawkSearch = {
		ClientGuid: RocHawkConfig.clientGuid,
		TrackingUrl: RocHawkConfig.trackingUrl,
		Context: Context,
		CustomContext: CustomContext,
	};
}

const HawkSearch = window.HawkSearch;

export default {
	Tracking: {
		track,
		// #region Commerce
		trackCommerce,
		// #endregion
	},
	Context,
	CustomContext,
};
