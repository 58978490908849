import React from 'react';
import classnames from 'classnames';
import { Localizer } from 'services/Localizer';
import { AxiosError } from 'axios';
import XSVG from 'shared/components/svg/XSVG';

type Theme = 'danger' | 'warning' | 'info' | 'success';

export interface ErrorAlertProps {
	/**
	 * The title of the error message such as "Uh Oh!" or "An Unexpected Error has Occurred!"
	 */
	title: string;

	/**
	 * The error message to display if an error object doesn't have a more appropriate message.
	 */
	message: React.ReactNode | string;

	children?: any;

	theme?: Theme;

	isTitleOnOwnRow?: boolean;

	/** The error responsible for this message. */
	error: AxiosError | Error | null;

	/**
	 * Method from parent that handles collapse state.
	 */
	onCollapse?: (collased: boolean) => void;

	/**
	 * Controlled collapse state from parent.
	 */
	isCollapsed?: boolean;
}

/**
 * Reusable Error Alert Component.
 */
export default function InlineAlert(props: ErrorAlertProps) {
	const {
		title = InlineAlert.defaultProps.title,
		children,
		theme = 'danger',
		isTitleOnOwnRow = false,
		error = InlineAlert.defaultProps.error,
		isCollapsed = false,
		onCollapse,
	} = props;

	let { message = InlineAlert.defaultProps.message } = props;

	const axiosError = error as AxiosError | null;
	if (axiosError?.isAxiosError && axiosError != null && axiosError.response == null && axiosError.request != null) {
		message = Localizer('ErrorMessageNetwork');
	}

	if (axiosError?.isAxiosError && (axiosError?.response?.status === 401 || axiosError?.response?.status === 403)) {
		message = Localizer('ErrorMessageForbidden');
	}

	if (axiosError?.response?.data?.message) {
		message = axiosError.response.data.message;
	}

	if (isCollapsed) {
		return null;
	}

	return (
		<div
			data-testid="inline-alert-window"
			className={classnames('roc-alert', {
				'roc-alert-danger': theme === 'danger',
				'roc-alert-warning': theme === 'warning',
				'roc-alert-info': theme === 'info',
				'roc-alert-success': theme === 'success',
			})}
		>
			{onCollapse ? (
				<button type="button" className="roc-alert__close-btn" onClick={() => onCollapse(true)}>
					<XSVG />
				</button>
			) : null}

			{title ? (
				<strong className={classnames('roc-alert-title', { 'roc-alert-title--space': isTitleOnOwnRow })}>
					{title}
				</strong>
			) : null}

			{message ? <span data-testid="inline-alert-text">{message}</span> : null}

			{children ? <> {children}</> : null}
		</div>
	);
}

/**
 * Error Alert Default Props
 */
InlineAlert.defaultProps = {
	title: Localizer('ErrorMessageGenericTitle'),
	message: Localizer('UnexpectedErrorOccurred'),
	error: null,
};
