/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { ControllerStateAndHelpers } from 'downshift';
import { Response, Product, Popular, Category, Content } from 'models/Autocomplete';
import { Suggestion, SuggestionType } from '../../../models/Autocomplete/Suggestion';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
/* #region Commerce */
import { dispatchRocEvent } from 'shared/hooks/useRocEventHandler';
import { CommerceSuggestType } from 'features/tracking/trackers/commerce/hawksearch/tracking-commerce';
/* #endregion */

export interface SearchSuggestionsListProps {
	isLoading: boolean;
	searchResults: Response;
	downshift: ControllerStateAndHelpers<Suggestion>;
	query: string;
	onViewMatches: (downshift: ControllerStateAndHelpers<Suggestion>) => void;
}

function hasAllEmpty(
	popular: Popular[] | undefined,
	categories: Category[] | undefined,
	products: Product[] | undefined,
	content: Content[] | undefined,
) {
	const hasPopular = popular && popular.length === 0;
	const hasCategories = categories && categories.length === 0;
	const hasProducts = products && products.length === 0;
	const hasContent = content && content.length === 0;
	return hasPopular && hasCategories && hasProducts && hasContent;
}

function getAtleastOneExist(
	popular: Popular[] | undefined,
	categories: Category[] | undefined,
	products: Product[] | undefined,
	content: Content[] | undefined,
) {
	const hasPopular = popular && popular.length > 0;
	const hasCategories = categories && categories.length > 0;
	const hasProducts = products && products.length > 0;
	const hasContent = content && content.length > 0;
	return hasPopular || hasCategories || hasProducts || hasContent;
}

function SearchSuggestionsList({
	isLoading,
	searchResults,
	downshift,
	query,
	onViewMatches,
}: SearchSuggestionsListProps) {
	const {
		Popular: popular,
		Categories: categories,
		Products: products,
		Content: content,
		PopularHeading,
		CategoryHeading,
		ProductHeading,
		ContentHeading,
	} = searchResults;

	const { getItemProps, getMenuProps, highlightedIndex } = downshift;
	const isRecordEmpty = hasAllEmpty(popular, categories, products, content);
	const isAtleastOneExist = getAtleastOneExist(popular, categories, products, content);
	const { t } = useTranslation();

	let dynamicIndex = 0;

	return (
		<ul className="dropdown-menu autosuggest-menu__list" {...getMenuProps()}>
			{isLoading && <li className="autosuggest-menu__item loading-label">Loading...</li>}
			{products && products.length ? (
				<>
					{ProductHeading ? <h3>{ProductHeading}</h3> : ''}
					{products.map((item) => {
						dynamicIndex++;

						return (
							<li
								key={dynamicIndex - 1}
								className={
									highlightedIndex === dynamicIndex - 1
										? 'autosuggest-menu__item autosuggest-menu__item--highlighted'
										: 'autosuggest-menu__item'
								}
								{...getItemProps({
									item,
									index: dynamicIndex - 1,
									/* #region Commerce */
									onClick: () => {
										dispatchRocEvent('hawk-autocomplete-click', {
											keyword: query,
											suggestType: CommerceSuggestType.TopProductMatches,
											itemName: item.ProductName,
											url: item.Url,
										});
									},
									/* #endregion */
								})}
							>
								<div dangerouslySetInnerHTML={{ __html: item.Thumb }} />
								{item.ProductName}
							</li>
						);
					})}
				</>
			) : null}
			<div>
				{categories && categories.length ? (
					<>
						<h3>{CategoryHeading}</h3>
						{categories.map((item) => {
							dynamicIndex++;

							/* #region HCL */
							item.Url = item.Url.replace(/%2c/g, '%c%');
							/* #endregion */

							return (
								<li
									key={dynamicIndex - 1}
									className={
										highlightedIndex === dynamicIndex - 1
											? 'autosuggest-menu__item autosuggest-menu__item--highlighted'
											: 'autosuggest-menu__item'
									}
									{...getItemProps({
										item,
										index: dynamicIndex - 1,
									})}
								>
									<div dangerouslySetInnerHTML={{ __html: item.Value }} />
								</li>
							);
						})}
					</>
				) : null}
				{popular && popular.length ? (
					<>
						<h3>{PopularHeading}</h3>
						{popular.map((item) => {
							dynamicIndex++;

							return (
								<li
									key={dynamicIndex - 1}
									className={
										highlightedIndex === dynamicIndex - 1
											? 'autosuggest-menu__item autosuggest-menu__item--highlighted'
											: 'autosuggest-menu__item'
									}
									{...getItemProps({
										item,
										index: dynamicIndex - 1,
									})}
								>
									<div dangerouslySetInnerHTML={{ __html: item.Value }} />
								</li>
							);
						})}
					</>
				) : null}
				{content && content.length ? (
					<>
						<h3>{ContentHeading}</h3>
						{content.map((item) => {
							dynamicIndex++;

							return (
								<li
									key={dynamicIndex - 1}
									className={
										highlightedIndex === dynamicIndex - 1
											? 'autosuggest-menu__item autosuggest-menu__item--highlighted'
											: 'autosuggest-menu__item'
									}
									{...getItemProps({
										item,
										index: dynamicIndex - 1,
									})}
								>
									{item.Thumb && <div dangerouslySetInnerHTML={{ __html: item.Thumb }} />}
									{item.Value}
								</li>
							);
						})}
					</>
				) : null}
			</div>
			{isAtleastOneExist && (
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events
				<li
					className={classnames('autosuggest-menu__item', {
						'autosuggest-menu__item--highlighted': highlightedIndex === products.length,
					})}
					{...getItemProps({
						item: { SuggestionType: SuggestionType.ViewAll },
						index: dynamicIndex,
						key: dynamicIndex,
					})}
					onClick={() => onViewMatches(downshift)}
				>
					View all matches
				</li>
			)}
			{!isLoading && isRecordEmpty && <li className="autosuggest-menu__item">{t('No results.')}</li>}
		</ul>
	);
}

export default SearchSuggestionsList;
