/**
 * @see HawkSearchConfig.apiUrl
 */
export const defaultApiUrl = 'https://searchapi-dev.hawksearch.net';

/**
 * @see HawkSearchConfig.dashboardUrl
 */
export const defaultDashboardUrl = 'https://dev.hawksearch.net';

/**
 * @see HawkSearchConfig.searchUrl
 */
export const defaultSearchUrl = '/api/v2/search';

/**
 * @see HawkSearchConfig.autocompleteUrl
 */
export const defaultAutocompleteUrl = '/api/autocomplete';

/**
 * @see HawkSearchConfig.searchPageUrl
 */
export const defaultSearchPageUrl = '/search';

/**
 * Endpoint for hawksearch's compare api
 */
export const defaultCompareItemsURL = '/api/v2/compare';

/**
 * @see HawkSearchConfig.recommendationUrl
 */
export const defaultRecommendationUrl = 'https://recs-dev.hawksearch.net';

/**
 * @see HawkSearchConfig.recommendationWidgetUrl
 */
export const recommendationWidgetUrl = '/api/recommendation/v2/getwidgetitems';

// #region HCL
/**
 * @see HawkSearchConfig.enableRemovingDashesFromSearchKeyword
 */
export const enableRemovingDashesFromSearchKeyword = false;
// #endregion
