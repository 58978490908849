import { AddressResourceKeys } from 'features/address-book/resources';
import { ContactFormResourceKeys } from 'features/contact-form/resources';
import { CreatePasswordResourceKeys } from 'features/create-password/resources';
import { LoginWith2faResourceKeys } from 'features/login-with-2fa/resources';
import { LoginWithRecoveryCodeResourceKeys } from 'features/login-with-recovery-code/resources';
import { LoginResourceKeys } from 'features/login/resources';
import { MyAccountResourceKeys } from 'features/my-account/resources';
import { ResetPasswordResourceKeys } from 'features/reset-password/resources';
import { SubmitQuestionResourceKeys } from 'features/submit-question/resources';
import * as React from 'react';
import { toKebabCase } from 'util/StringUtil';

// #region Commerce
import { CommerceResourceKeys } from 'features/commerce/CommerceResourceKeys';
// #endregion

// #region HCL
import { QuickBagsResourceKeys } from 'features/hcl/quick-bags/resources';
import { QuickLabelsResourceKeys } from 'features/hcl/quick-labels/resources';
import { ProductCatalogsResourceKeys } from 'features/hcl/product-catalogs/resources';
import { FileUploadResourceKeys } from 'features/hcl/file-upload/resources';
import { NewsletterKeys } from 'features/hcl/newsletter-signup/resources';
// #endregion

/**
 * Type that will hold all the global localization keys
 */
type LocalizerGlobalResourceKeys =
	| 'Success'
	| 'OfTotalPages'
	| 'Cancel'
	| 'Confirm'
	| 'FirstName'
	| 'LastName'
	| 'Email'
	| 'Username'
	| 'Amount'
	| 'NameOnCard'
	| 'CardNumber'
	| 'ExpDate'
	| 'ExpMonth'
	| 'ExpYear'
	| 'Expires'
	| 'Expired'
	| 'SecurityCode'
	| 'Submit'
	| 'Save'
	| 'ZipCode'
	| 'Remove'
	| 'Removed'
	| 'Update'
	| 'Undo'
	| 'Cancel'
	| 'Enter'
	| 'Close'
	| 'SortBy'
	| 'PleaseWait'
	| 'Unknown'
	| 'Yes'
	| 'No'
	| 'Delete'
	| 'Update'
	| 'SaveAndContinueButtonText'
	| 'SaveAndContinue'
	| 'Continue'
	| 'SaveErrorMessage'
	| 'Edit'
	| 'ErrorMessageGenericTitle'
	| 'CheckEnteredValues'
	| 'ErrorMessageForbidden'
	| 'ErrorMessageNetwork'
	| 'ErrorOccurredSubmittingTheForm'
	| 'UnexpectedErrorOccurred'
	| 'Login'
	| 'ReturnToShipping'
	| 'BreadcrumbsLabel'
	| 'Other'
	| 'RememberMe'
	| 'ForgotYourPassword'
	| 'RegisterNewUserLinkText'
	| 'LoginPageTitleText'
	| 'ForgotPasswordPageTitle'
	| 'ForgotPasswordConfirmationPageTitle'
	| 'ForgotPasswordConfirmation'
	| 'Optional'
	| 'SearchBoxPlaceholder'
	| 'XOutOfYStars'
	| 'XToYOfZ'
	| 'ModalLabel'
	| 'RequiredFields'
	| 'Status'
	| 'Version'
	| 'CreatedOn'
	| 'CreatedBy'
	| 'ModifiedOn'
	| 'ModifiedBy'
	| 'OpenStatusInformation'
	| 'CloseStatusInformation'
	| 'SearchFilter'
	| 'Pagination'
	| 'Previous'
	| 'Next'
	| 'ClearFilter';

/**
 * Type that will hold all the localization keys
 */
export type LocalizerResourceKey =
	| LocalizerGlobalResourceKeys
	| LoginResourceKeys
	| ContactFormResourceKeys
	// #region Commerce
	| CommerceResourceKeys
	// #endregion
	| ResetPasswordResourceKeys
	| CreatePasswordResourceKeys
	| SubmitQuestionResourceKeys
	| ResetPasswordResourceKeys
	| CreatePasswordResourceKeys
	| MyAccountResourceKeys
	| LoginWith2faResourceKeys
	| LoginWithRecoveryCodeResourceKeys
	| AddressResourceKeys
	// #region HCL
	| QuickBagsResourceKeys
	| QuickLabelsResourceKeys
	| ProductCatalogsResourceKeys
	| NewsletterKeys
	| FileUploadResourceKeys;
// #endregion

/**
 * Interface for the resources
 *
 * @export
 * @interface LocalizerResource
 */
interface LocalizerResource {
	[id: string]: string;
}

/**
 * Extends Window with a property that will hold the localizer resources
 *
 * @interface WindowWithResources
 * @extends {Window}
 */
export interface WindowWithResources extends Window {
	__ROC_LOCALIZER_RESOURCES__?: LocalizerResource[];
}

/**
 * Main object that will hold all the localized resources
 */
const LocalizerResources: LocalizerResource = {};

/**
 * To prevent redundant logging
 */
const isAlreadyLogged: Record<string, boolean> = {};

/**
 * Initializer function that will populate the main localized resources object
 */
function initializeLocalizerResources() {
	const windowWithResources = window as WindowWithResources;

	if (
		windowWithResources &&
		windowWithResources.__ROC_LOCALIZER_RESOURCES__ &&
		Array.isArray(windowWithResources.__ROC_LOCALIZER_RESOURCES__)
	) {
		windowWithResources.__ROC_LOCALIZER_RESOURCES__.forEach((obj) => {
			for (const key of Object.keys(obj)) {
				LocalizerResources[key] = obj[key];
			}
		});
	}
}

/**
 * Function that can be used to load the localized strings with support to parameter replacements
 *
 * @export
 * @param {string} key
 * @param {...string[]} replaceStrings
 * @returns
 */
export function Localizer<TResourceKey extends LocalizerResourceKey = LocalizerResourceKey>(
	key: TResourceKey,
	...replaceStrings: string[]
) {
	if (Object.keys(LocalizerResources).length === 0) {
		initializeLocalizerResources();
	}

	if (!LocalizerResources || !LocalizerResources[key]) {
		// if running in a test environment, just skip any warnings.
		if (process.env.NODE_ENV !== 'test') {
			if (!isAlreadyLogged[key]) {
				console.warn(key, 'Localizer Resource not found. Key will be used instead.');
				isAlreadyLogged[key] = true;
			}
		}
		return key;
	}

	const resource = LocalizerResources[key];

	if (!replaceStrings) {
		return resource;
	}

	return resource.replace(/{(\d+)}/g, (match: string, index: number) => {
		return replaceStrings[index] ? replaceStrings[index] : '';
	});
}

/**
 * Interface for the LocalizedSpan component
 *
 * @interface LocalizedSpanProps
 */
interface LocalizedSpanProps {
	resourceKey: LocalizerResourceKey;
	args?: string[];
}

/**
 * Component responsible for the initialization of the react app
 */
export function LocalizedSpan(props: LocalizedSpanProps) {
	const { resourceKey, args = [] } = props;
	const localizedString = Localizer(resourceKey, ...args);

	return (
		<span
			data-testid={`localized-${toKebabCase(resourceKey)}`}
			dangerouslySetInnerHTML={{ __html: localizedString }}
		/>
	);
}
