import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import AccountUrls from 'shared/util/AccountUrls';
import { getCsrfToken } from './UserData';

/**
 * Helper that creates an instance of Axios to be used throughout the client application
 * and adds a default X-Requested-With header to all requests.
 *
 */
const AxiosHelper: AxiosInstance = axios.create();
AxiosHelper.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Add CSRF tokens to all commands that are not GET, HEAD, or OPTIONS
AxiosHelper.interceptors.request.use(async (config) => {
	if (
		config.method?.toLowerCase() !== 'get' &&
		config.method?.toLowerCase() !== 'head' &&
		config.method?.toLowerCase() !== 'options'
	) {
		const csrfToken = await getCsrfToken();
		config.headers = { RequestVerificationToken: csrfToken, ...config.headers };
	}
	return config;
});

export default AxiosHelper;

// eslint-disable-next-line no-unused-vars
export const addUnauthorizedAccessInterceptor = (history) => {
	// this allows us to act when any request going through axios succeeds or fails.
	AxiosHelper.interceptors.response.use(
		(response: AxiosResponse) => {
			return response;
		},
		(error: AxiosError) => {
			if (error.response && error.response.status === 401) {
				window.location.replace(
					`${AccountUrls.Login}?ReturnUrl=${encodeURIComponent(window.location.pathname)}`,
				);
			}

			return Promise.reject(error);
		},
	);
};
