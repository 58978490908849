import { ExtractEventParameters } from 'shared/events';
import { makeRequest, preparePayload } from '../../hawksearch/tracking';

export enum CommerceEventTrackingType {
	addToCart = 4,
	rate = 5,
	sale = 6,
	add2CartMultiple = 14,
}

/**
 * The page type
 */
export enum CommercePageType {
	/**
	 * Item detail page (i.e. PDP)
	 */
	item = 1,
	/**
	 * Shopping Cart Page
	 */
	cart = 3,
	/**
	 * Order Confirmation Page
	 */
	order = 4,
}

export enum CommerceSuggestType {
	TopCategories = 2,
	TopProductMatches = 3,
}

type CommerceHawkEvents =
	| ({ name: 'sale' } & TrackSaleArgs)
	| ({ name: 'add2cart' } & TrackAddToCartArgs)
	| ({ name: 'add2cartmultiple' } & TrackAddToCartMultipleArgs)
	| ({ name: 'rate' } & TrackRateArgs);

export type CommerceHawkEventType = CommerceHawkEvents['name'];

export function trackCommerce<T extends CommerceHawkEventType>(
	eventName: T,
	args: ExtractEventParameters<CommerceHawkEvents, T>,
) {
	switch (eventName.toLocaleLowerCase()) {
		case 'sale':
			//HawkSearch.Tracking.track('sale', {orderNo: 'order_123',itemList: [{uniqueid: '123456789', itemPrice: 12.99, quantity: 2}], total: 25.98, subTotal: 22, tax: 3.98, currency: 'USD'});
			return trackSale((args as unknown) as TrackSaleArgs);
		case 'add2cart':
			//HawkSearch.Tracking.track('add2cart',{uniqueId: '123456789', price: 19.99, quantity: 3, currency: 'USD'});
			return trackAddToCart((args as unknown) as TrackAddToCartArgs);
		case 'add2cartmultiple':
			//HawkSearch.Tracking.track('add2cartmultiple', [{uniqueId: '123456789',price: 15.97,quantity: 1,currency: 'USD'},{uniqueId: '987465321', price: 18.00, quantity: 1, currency: 'USD'}]);
			return TrackAddToCartMultiple((args as unknown) as TrackAddToCartMultipleArgs);
		case 'rate':
			//HawkSearch.Tracking.track('rate', {uniqueId: '123456789',value: 3.00});
			return trackRate((args as unknown) as TrackRateArgs);
	}
}

export type TrackSaleItemArgs = {
	/**
	 * Represents the unique identifier of a product. This should correspond to the value of the field set up as the primary key in the fields section of Hawksearch dashboard
	 */
	uniqueId: string;
	/**
	 * Represents a single item price
	 */
	itemPrice: number;
	/**
	 * The number of items being added to the cart
	 */
	quantity: number;
};

type TrackSaleArgs = {
	/**
	 * The alphanumeric value representing the order number
	 */
	orderNo: string;
	/**
	 * Is an array of objects that stores information about items. To see object properties check Sale Item Info Model below.
	 */
	itemList: TrackSaleItemArgs[];
	/**
	 * The actual order total amount
	 */
	total?: number;
	/**
	 * The order the sub total amoun
	 */
	subTotal?: number;
	/**
	 * The tax value
	 */
	tax?: number;
	/**
	 * The actual currency in ISO format
	 */
	currency?: string;
};

function trackSale({ orderNo, itemList, total, subTotal, tax, currency }: TrackSaleArgs) {
	const payload = {
		EventType: CommerceEventTrackingType.sale,
		EventData: preparePayload({
			OrderNo: orderNo,
			ItemList: itemList,
			Total: total,
			Tax: tax,
			SubTotal: subTotal,
			Currency: currency,
		}),
	};

	makeRequest(payload);
}

export type TrackAddToCartArgs = {
	/**
	 * Represents the unique identifier of a product. This should correspond to the value of the field set up as the primary key in the fields section of Hawksearch dashboard
	 */
	uniqueId: string;
	/**
	 * Represents a single item price
	 */
	price: number;
	/**
	 * The number of items being added to the cart
	 */
	quantity: number;
	/**
	 * Actual currency in ISO format
	 */
	currency: string;
};

function trackAddToCart({ uniqueId, price, quantity, currency }: TrackAddToCartArgs) {
	const payload = {
		EventType: CommerceEventTrackingType.addToCart,
		EventData: preparePayload({
			UniqueId: uniqueId,
			Quantity: quantity,
			Price: price,
			Currency: currency,
		}),
	};

	makeRequest(payload);
}

type TrackAddToCartMultipleArgs = {
	/**
	 * Is an array of objects that stores information about items
	 */
	itemsList: TrackAddToCartArgs[];
};

function TrackAddToCartMultiple({ itemsList }: TrackAddToCartMultipleArgs) {
	const payload = {
		EventType: CommerceEventTrackingType.add2CartMultiple,
		EventData: preparePayload({
			ItemsList: itemsList,
		}),
	};

	makeRequest(payload);
}

type TrackRateArgs = {
	/**
	 * The unique identifier of an item. In many cases the uniqueId is different than SKU.
	 */
	uniqueId: string;
	/**
	 * Represents a user’s rating for an item. The decimal value must be between 1 and 5.
	 */
	value: number;
};

function trackRate({ uniqueId, value }: TrackRateArgs) {
	const payload = {
		EventType: CommerceEventTrackingType.rate,
		EventData: preparePayload({
			UniqueId: uniqueId,
			Value: value,
		}),
	};
	makeRequest(payload);
}
