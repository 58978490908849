import AxiosHelper from 'services/AxiosHelper';
import { AxiosResponse } from 'axios';

export interface UserSummary {
	isSignedIn: boolean;
	userName: string | null;
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	phoneNumber: string | null;
	twoFactorEnabled: boolean;
	hasRecoveryCodes: boolean;
	isMachineRemembered: boolean;
	announcements: UserAnnouncement[];
	isExternalLoginUser: boolean;
	// #region HCL
	versaPayEnabled: boolean;
	versaPayRedirectUrl: string | null;
	azureUserId: string | null;
	// #endregion
}

/** Represents the details about an admin impersonation session of the logged in user. */
export interface Impersonation {
	/** The id of the admin impersonating the front end user. */
	impersonatorId: string;

	/** The name of the admin impersonating the front end user. */
	impersonatorName: string;

	/** The id of the active user impersonation session. */
	sessionId: string;
}

export interface UserData {
	/**
	 * Summary of user-specific details
	 */
	summary?: UserSummary;

	/**
	 * CSRF validation token generated by the server
	 */
	requestToken?: string;

	/**
	 * The information on an impersonation session if one is active.
	 * If an impersonation session isn't active this field will be undefined.
	 */
	impersonation?: Impersonation;
}

export interface UserAnnouncement {
	/** The id used as the primary key for the announcement in the database */
	id: string;

	/** An announcement to be displayed to the user */
	message: string;

	/** A list of pages where the announcement will be displayed. This will be an empty array if it is to be displayed on all pages. */
	pages: string[];
}

let userDataPromise: Promise<AxiosResponse<UserData>> | null = null;

/**
 * Returns details related to the current user. By default, this is primarily their first/last name
 * and whether they are logged in or not. This can customized to include more details if necessary.
 */
export const getUserData = () => {
	if (userDataPromise === null) {
		userDataPromise = AxiosHelper.get<UserData>('/ajax/user');
	}

	return userDataPromise;
};

/**
 * Returns non-cached details related to the current user. By default, this is primarily their first/last name
 * and whether they are logged in or not. This can customized to include more details if necessary.
 */
export const getUserDataNoCache = () => {
	return AxiosHelper.get<UserData>('/ajax/user');
};

/**
 * Returns a promise that will contain the CSRF token generated on the server to be used to validate
 * forms being posted via AJAX.
 */
export async function getCsrfToken() {
	try {
		const userData = await getUserData();
		return userData.data.requestToken;
	} catch (error) {
		console.warn('Unable to load CSRF token.');
		return '';
	}
}

// #region Commerce
import { CartSummary } from './UserData.Commerce';
import { LocationDto } from 'features/commerce/store-locator/types';
import { OrganizationInvite, OrganizationShipTo } from 'shared-client/types/commerce/organization';
import { AutoSuggestOption } from '../../../Roc.SharedClientApp/types/fields';

// Commerce extensions to the UserData interface
export interface UserData {
	// #region HCL - allows null
	cartSummary?: CartSummary | null;
	// #endregion

	/***
	 * The customer's default store location or null if not set
	 */
	defaultStoreLocation?: LocationDto | null;

	/**
	 * If store is enabled for this site
	 */
	enableStoreLocation?: boolean;
}

/***
 * Organization data and permissions
 */
export interface Organization {
	id: string;
	purchaseOrderNumberRequired: boolean;
	creditCardAllowed: boolean;
	giftCardAllowed: boolean;
	organizationName: string;
	corpAccountAllowed: boolean;
}

/***
 * Organization dto data
 */
export interface OrganizationDto extends AutoSuggestOption {}

/***
 * User's organization and permissions data
 */
export interface UserSummary {
	/***
	 * User's current default organization
	 */
	organization: Organization | null;

	/***
	 * The list of organizations the user is part of
	 */
	userOrganizations?: OrganizationDto[] | null;

	/***
	 * The selected organization ship to
	 */
	selectedShipTo: OrganizationShipTo | null;

	/***
	 * Defines if the user has multiple shiptos to select from
	 */
	hasMultipleShipTos: boolean | null;

	/***
	 * Defines if the user has saved payment methods
	 */
	hasSavedPaymentMethods: boolean | null;

	/***
	 * The list of user permissions
	 */
	permissions?: UserPermissions;

	/***
	 * The list of organizations the user is invited to join
	 */
	organizationInvites?: OrganizationInvite[];

	/***
	 * The user organization removal notification
	 * Used to notify the user if he is no more part of his default organization
	 */
	userOrganizationRemovalNotification?: string;
}

/***
 * User's permissions
 */
export interface UserPermissions {
	/***
	 * Defines if the current user can see organization users
	 */
	canSeeOrganizationUsers?: boolean;

	/***
	 * Defines if the current user can assign roles to organization users
	 */
	canAssignRolesToOrganizationUsers?: boolean;

	/***
	 * Defines if the current user can remove users from an organization
	 */
	removeUserFromOrganization?: boolean;

	/***
	 * Defines if the current user can create organization users
	 */
	createOrganizationUser?: boolean;

	/***
	 * Defines if the current user can enable/disable ShipTos for organization users
	 */
	manageShipTosAssociation?: boolean;

	/***
	 * Defines if the current user can see status of the organization users
	 */
	canSeePendingOrganizationUsers?: boolean;
}
// #endregion
