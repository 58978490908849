import React, { useState } from 'react';
import { DownshiftState, StateChangeOptions, ControllerStateAndHelpers } from 'downshift';
import SearchSuggestions from 'components/ui/SearchBox/SearchSuggestions';
import MagnifyingGlassSVG from 'shared/components/svg/MagnifyingGlassSVG'; // ERRONEOUS: Referencing stuff outside of Hawk from within Hawk. This should be inverted.
import { Suggestion } from 'models/Autocomplete/Suggestion';
import { getAutocompleteStrategies } from '../Facets/Overrides';
import { useHawkConfig } from 'components/ConfigProvider';
import { useTranslation } from 'react-i18next';

const Downshift = React.lazy(() => import(/* webpackChunkName: "downshift" */ 'downshift'));

export interface SearchBoxBaseProps {
	initialValue?: string;
	onSubmit: (keywords: string) => void;
	onViewMatches: (downshift: ControllerStateAndHelpers<Suggestion>) => void;
}

function SearchBoxBase({ initialValue, onSubmit, onViewMatches }: SearchBoxBaseProps) {
	const { config } = useHawkConfig();
	const strategies = getAutocompleteStrategies(config.autocompleteStrategies || []);
	const [isOpenOverride, setIsOpenOverride] = useState(true);
	const { t } = useTranslation();

	/** Called when the internal state of downshift changes - we're handling a couple custom behaviors here */
	function handleStateChange(
		state: DownshiftState<Suggestion>,
		changes: StateChangeOptions<Suggestion>,
	): Partial<StateChangeOptions<Suggestion>> {
		if (
			// NOTE: these strings are hardcoded to support code splitting downshift.
			// using the constants from the package will prevent code splitting from operating correctly
			changes.type === '__autocomplete_mouseup__' ||
			changes.type === '__autocomplete_keydown_enter__' ||
			changes.type === '__autocomplete_click_item__'
		) {
			// when:
			//
			//  1. the mouse the clicked outside of downshift
			//  2. enter is pressed on the keyboard
			//  3. an item is selected from the dropdown
			//
			// then we want to retain the input value that was originally typed in. by default downshift
			// will clear the input value, so we're overriding this behavior here.
			return { ...changes, inputValue: state.inputValue };
		}

		return changes;
	}

	/** Called when an item has been selected from the autocomplete results. */
	function handleItemChange(item: Suggestion, downshift: ControllerStateAndHelpers<Suggestion>) {
		if (!item) {
			return;
		}

		const matchedStrategy = strategies.find((strategy) => strategy.type === item.SuggestionType);
		if (!matchedStrategy) {
			return;
		}

		matchedStrategy.strategy.handleItemChange(item, downshift);
	}

	function handleToString(item: Suggestion): string {
		if (!item) {
			return '';
		}
		const matchedStrategy = strategies.find((strategy) => strategy.type === item.SuggestionType);
		if (!matchedStrategy) {
			return '';
		}

		return matchedStrategy.strategy.toString(item);
	}

	return (
		<div className="hawk">
			<React.Suspense fallback={<div>{t('Loading')}...</div>}>
				<Downshift
					stateReducer={handleStateChange}
					itemToString={(item: Suggestion) => handleToString(item)}
					onChange={handleItemChange}
					initialInputValue={initialValue || ''}
				>
					{(options: ControllerStateAndHelpers<Suggestion>) => {
						const { isOpen, inputValue, getInputProps, openMenu } = options;
						const showSuggestions = isOpen && inputValue && inputValue.length > 0 && isOpenOverride;

						return (
							<div className="hawk-search-form" data-testid="global-search-box">
								<label {...options.getLabelProps()} htmlFor="global-search-text-input">
									<span className="roc-visually-hidden">Autocomplete search box</span>
									<input
										className="hawk-search-form__input"
										type="text"
										{...getInputProps({
											onKeyDown: (event) => {
												if (event.key === 'Enter' && onSubmit) {
													onSubmit(inputValue || '');
													setIsOpenOverride(false);
												} else {
													setIsOpenOverride(true);
												}
											},

											// #region HCL -> very hacky! submit form if blur was caused by navigation to page size
											// This is what happens when enter is clicked in Soft Keyboard on Android.
											// Users typically would not click on the page size dropdown while typing in their search clause
											onBlur: (event) => {
												const relTarget = event.relatedTarget as HTMLElement;
												if (relTarget?.id === 'hawk-items-per-page-selector' && onSubmit) {
													console.log('focus moved to page down dropdown');
													onSubmit(inputValue || '');
												}
											},
											// #endregion

											// when the input is focused again, reopen the downshift menu
											onFocus: () => {
												if (inputValue && inputValue.length > 0) {
													openMenu();
												}
											},

											placeholder: config.searchBoxPlaceholder
												? config.searchBoxPlaceholder
												: t('Enter a search term'),
										})}
										data-testid="global-search-text-input"
										id="global-search-text-input"
									/>
								</label>

								<button
									type="button"
									className="hawk-search-form__btn"
									onClick={() => {
										if (onSubmit) {
											onSubmit(inputValue ?? '');
										}
									}}
									data-testid="global-search-button"
								>
									<MagnifyingGlassSVG className="hawk-search-form__input-icon" />
									<span className="roc-visually-hidden">Search</span>
								</button>

								{showSuggestions ? (
									<SearchSuggestions
										query={inputValue ?? ''}
										downshift={options}
										onViewMatches={onViewMatches}
									/>
								) : null}
							</div>
						);
					}}
				</Downshift>
			</React.Suspense>
		</div>
	);
}

export default SearchBoxBase;
