import React from 'react';

import 'shared/components/hawksearch/styles/app.scss';

import ConfigProvider from 'shared/components/hawksearch/components/ConfigProvider';
import GlobalSearchBox from 'shared/components/hawksearch/components/ui/SearchBox/GlobalSearchBox';
import hawkConfig from './RocHawkConfig';
import { initReactApp } from 'shared/util/ApplicationInit';

const render = initReactApp(
	() => (
		<ConfigProvider config={hawkConfig}>
			<GlobalSearchBox />
		</ConfigProvider>
	),
	'searchbox',
);

render();
