/**
 * Returns the input string without dashes if the input matches this regex /^[A-Za-z0-9]{0,7}-[A-Za-z0-9]{0,7}$/.
 * The regex should be validated so the string contains at most 7 characters before the dash & Matches at most 7 characters after the dash
 */

export function validateQueryAndRemoveDash(inputString) {
	// Matches at most 7 characters before the dash & Matches at most 7 characters after the dash
	const regex = /^[A-Za-z0-9]{0,7}-[A-Za-z0-9]{0,7}$/;

	if (regex.test(inputString)) {
		return inputString.replace('-', '');
	}

	return inputString;
}
