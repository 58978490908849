/*eslint camelcase: "off"*/
import React, { useContext } from 'react';

import { HawkSearchConfig } from 'types/HawkSearchConfig';

const ConfigContext = React.createContext({} as ConfigContextValue);

declare global {
	interface Window {
		__webpack_public_path__: string;
	}
}

export interface ConfigContextValue {
	/** Global configuration. */
	config: HawkSearchConfig;
}

export interface ConfigProviderProps {
	config: HawkSearchConfig;
	children: React.ReactNode;
}

function ConfigProvider({ config, children }: ConfigProviderProps) {
	if (config.assetPath) {
		let path = config.assetPath;

		// ensure the provided path both starts and ends with a slash
		if (!path.startsWith('/')) {
			path = '/' + path;
		}
		if (!path.endsWith('/')) {
			path = path + '/';
		}

		// allow consumers to tell webpack where to load code split/lazy loaded files from, as they may not be
		// hosting our JS from /assets/ (the default path)
		// eslint-disable-next-line
		window.__webpack_public_path__ = path;
	}

	return <ConfigContext.Provider value={{ config }}>{children}</ConfigContext.Provider>;
}

export function useHawkConfig() {
	const context = useContext(ConfigContext);

	if (!context.config) {
		throw new Error(
			'No HawkSearchConfig is available, did you forget to wrap your components in a ConfigProvider component?',
		);
	}

	return context;
}

export default ConfigProvider;
