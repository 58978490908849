import React, { useState, useRef } from 'react';
import { useHawkSearch } from 'components/StoreProvider';
import { useFacet } from 'components/ui/Facets/Facet';
import { useTranslation } from 'react-i18next';
import MagnifyingGlassSVG from 'shared/components/svg/MagnifyingGlassSVG';

function Search() {
	const { store, actor: hawkActor } = useHawkSearch();
	const { facet, actor } = useFacet();

	const [input, setInput] = useState<string | undefined>(undefined);

	const searchInputRef = useRef<HTMLInputElement | null>(null);
	const { t } = useTranslation();

	function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
		if (event.key === 'Enter') {
			setInput(undefined); // clear the user's entered value as we want to be driven by the store again

			actor.selectFacet(event.currentTarget.value);
		}
	}

	function clearFacet() {
		setInput(undefined); // clear the user's entered value as we want to be driven by the store again

		hawkActor.clearFacet(facet);
	}

	function getInputValue() {
		if (input !== undefined) {
			// if the user type an input, that's the value for the input
			return input;
		}

		// otherwise, use the value from the store
		return store.pendingSearch.SearchWithin ?? '';
	}

	const labelFor = `search-within-${facet.FacetId.toString()}`;

	return (
		<>
			<div className="hawk-facet-search">
				<label htmlFor={labelFor}>
					<span className="roc-visually-hidden">{facet.Name}</span>
					<input
						className="hawk-facet-search__input"
						value={getInputValue()}
						onChange={(e) => setInput(e.currentTarget.value)}
						onKeyDown={onKeyDown}
						type="text"
						ref={searchInputRef}
						name={labelFor}
					/>
				</label>

				<button
					className="hawk-facet-search__search-button"
					onClick={() => {
						if (searchInputRef && searchInputRef.current) {
							setInput(undefined);

							actor.selectFacet(searchInputRef.current.value);
						}
					}}
					aria-label={facet.Name}
				>
					<MagnifyingGlassSVG className="hawk-facet-search__icon" />
				</button>
			</div>

			{store.pendingSearch.SearchWithin ? (
				<div className="hawk-facet-search__clear-wrapper">
					<button className="hawk-facet-search__clear btn-link" onClick={clearFacet}>
						{t('Clear')}
					</button>
				</div>
			) : null}
		</>
	);
}

export default Search;
