import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Question Mark within a Circle SVG
 */
function QuestionMarkCircleSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 30 30"
			className={classnames('roc-icon', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-115.000000, -452.000000)" fill="#3F4044" fillRule="nonzero">
					<g transform="translate(90.000000, 291.000000)">
						<g transform="translate(0.000000, 36.000000)">
							<g transform="translate(0.000000, 100.000000)">
								<path d="M39.9999642,54.2812422 C48.0247712,54.2812422 54.5311796,47.7771798 54.5311796,39.7500268 C54.5311796,31.7275659 48.0247712,25.2188115 39.9999642,25.2188115 C31.9751573,25.2188115 25.4687489,31.7275659 25.4687489,39.7500268 C25.4687489,47.7771798 31.9751573,54.2812422 39.9999642,54.2812422 Z M39.9999642,51.4687489 C33.523482,51.4687489 28.2812422,46.2286834 28.2812422,39.7500268 C28.2812422,33.275719 33.5237109,28.0313048 39.9999642,28.0313048 C46.4740432,28.0313048 51.7186863,33.2737163 51.7186863,39.7500268 C51.7186863,46.2263946 46.4786208,51.4687489 39.9999642,51.4687489 Z M41.3372604,43.031269 C41.7255575,43.031269 42.0403837,42.7164428 42.0403837,42.3281457 L42.0403837,41.956843 C42.0403837,40.5049345 46.2837856,40.4444527 46.2837856,36.5156481 C46.2837856,33.5568997 43.2147164,31.3125469 40.3394524,31.3125469 C37.5941358,31.3125469 35.8307771,32.4546074 34.4463886,34.4874165 C34.235074,34.7978368 34.302079,35.2193217 34.6013412,35.4462003 L36.2316055,36.6824453 C36.536647,36.9136727 36.9704343,36.8579401 37.2081275,36.5579911 C38.0898926,35.4451703 38.7171418,34.8019566 40.0758384,34.8019566 C41.1179349,34.8019566 42.4069371,35.4726933 42.4069371,36.4832042 C42.4069371,37.2471527 41.7763119,37.6395124 40.7473189,38.2163505 C39.5474087,38.8890898 37.9594875,39.7263376 37.9594875,41.8207731 L37.9594875,42.3281457 C37.9594875,42.7164428 38.2743137,43.031269 38.6626109,43.031269 L41.3372604,43.031269 Z M39.9999642,48.7734428 C41.3569442,48.7734428 42.4608959,47.6694911 42.4608959,46.3125112 C42.4608959,44.9555312 41.3569442,43.8515795 39.9999642,43.8515795 C38.6429843,43.8515795 37.5390326,44.9555312 37.5390326,46.3125112 C37.5390326,47.6694911 38.6429843,48.7734428 39.9999642,48.7734428 Z"></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default QuestionMarkCircleSVG;
