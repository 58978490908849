import { ExternalLoginProvider } from 'features/login/ExternalLogins';

/**
 * Extends Window with a property that will hold the client data
 *
 * @interface WindowWithClientData
 * @extends {Window}
 */
interface WindowWithServerData extends Window {
	__ROC_SERVER_DATA__?: Array<{
		[name: string]: string;
	}>;
}

/**
 * This type will hold all the keys that are used by ServerData
 */
interface ServerData {
	IsReCaptchaEnabled?: boolean;
	ReCaptchaV3Key?: string;
	TimeZone?: string;
	USE_EMAIL_AS_USERNAME?: boolean;
	RegistrationThankYouTitle?: string;
	RegistrationThankYouMessage?: string;
	ForgotPasswordConfirmationTitle?: string;
	ForgotPasswordConfirmationMessage?: string;
	PageTitleSuffix?: string;
	RequireConfirmEmail?: boolean;
	ACCOUNT_URL_BASE?: string;
	ACCOUNT_LOGIN_URL?: string;
	ACCOUNT_LOGOUT_URL?: string;
	ACCOUNT_REGISTER_URL?: string;
	ACCOUNT_LOGIN_WITH_2FA_URL?: string;
	ACCOUNT_FORGOT_PASSWORD_URL?: string;
	ACCOUNT_LOGIN_WITH_RECOVERY_CODE_URL?: string;
	EXTERNAL_LOGIN_PROVIDERS?: ExternalLoginProvider[];
	// #region Commerce
	ACCOUNT_ORGANIZATION_REVIEW_URL?: string;
	ACCOUNT_ORGANIZATION_SELECTOR_URL?: string;
	GoogleMapsApiKey?: string; // used in Store Locator
	MinimumTimeBeforeRecentlyViewedProductsAreValidated?: number;
	QuickOrderItemMinumumSearchCharacters?: number;
	MaxAllowedQuickOrderItems?: number;
	MaxNumberForQuantityDropdown?: number;
	OrderDetailsUIComponent?: string;
	GuestCheckoutEnabled?: boolean;
	// #endregion
}

/**
 * Main object that will hold all the client data
 */
export const ServerData: ServerData = {};

/**
 * Initializer function that will populate the main settings object
 */
function initializeServerData() {
	const windowWithServerData = window as WindowWithServerData;

	if (
		windowWithServerData &&
		windowWithServerData.__ROC_SERVER_DATA__ &&
		Array.isArray(windowWithServerData.__ROC_SERVER_DATA__)
	) {
		for (const obj of windowWithServerData.__ROC_SERVER_DATA__) {
			for (const key of Object.keys(obj)) {
				ServerData[key] = obj[key];
			}
		}
	}
}

initializeServerData();
