import * as React from 'react';

interface PlusSVGProps {
	class?: string;
}

/**
 * Plus SVG
 *
 * @returns
 */
function PlusThinSVG(props: PlusSVGProps) {
	return (
		<svg viewBox="0 0 24 24" className={'icon ' + props.class} focusable="false" aria-hidden="true">
			<path d="M13.5 0v10.5h10.5v3h-10.5v10.5h-3v-10.5h-10.5v-3h10.5v-10.5h3z"></path>
		</svg>
	);
}

export default PlusThinSVG;
