import * as React from 'react';

interface MinusSVGProps {
	class?: string;
}

/**
 * Minus SVG
 *
 * @returns
 */
function MinusThinSVG(props: MinusSVGProps) {
	return (
		<svg viewBox="0 0 144 24" className={'icon ' + props.class} focusable="false" aria-hidden="true">
			<path d="M0 0h144v18h-144v-18z"></path>
		</svg>
	);
}

export default MinusThinSVG;
