import { HawkSearchConfig } from 'shared/components/hawksearch/types/HawkSearchConfig';
import * as Constants from 'util/Constants';
import { Localizer } from 'services/Localizer';

const searchState = (window as any).__ROC_SEARCH_STATE__;

const RocHawkConfig: HawkSearchConfig = {
	apiUrl: searchState.searchEndpoint as string,
	clientGuid: searchState.trackingKey as string,
	searchPageUrl: (searchState.searchPageUrl as string) || '/search',
	dashboardUrl: (searchState.dashboardUrl as string) || Constants.defaultDashboardUrl,
	searchBoxPlaceholder: Localizer('SearchBoxPlaceholder'),
	trackingUrl: searchState.trackingUrl as string,
	/* #region Commerce */
	recommendationUrl: searchState.recommendationEndpoint as string,
	enableRemovingDashesFromSearchKeyword: searchState.enableRemovingDashesFromSearchKeyword as boolean,
	/* #endregion */
};

export default RocHawkConfig;
