import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Spinner SVG for loading purposes
 *
 * @returns
 */
function MagnifyingGlassSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 32 32"
			className={classnames('roc-icon roc-icon-magnifying-glass', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path d="M31.118 31.12c-0.544 0.544-1.295 0.88-2.125 0.88s-1.581-0.336-2.125-0.88l-5.304-5.304c-2.151 1.398-4.782 2.23-7.607 2.23-7.782 0-14.091-6.309-14.091-14.091s6.309-14.091 14.091-14.091c7.782 0 14.091 6.309 14.091 14.091 0 2.827-0.833 5.46-2.266 7.666l0.033-0.055 5.304 5.304c0.544 0.544 0.881 1.295 0.881 2.125s-0.337 1.581-0.881 2.125l-0 0zM14.022 4c-5.533 0-10.018 4.485-10.018 10.018s4.485 10.018 10.018 10.018c5.533 0 10.018-4.485 10.018-10.018v0c0-0 0-0.001 0-0.002 0-5.532-4.484-10.016-10.016-10.016-0.001 0-0.001 0-0.002 0h0z" />
		</svg>
	);
}

export default MagnifyingGlassSVG;
