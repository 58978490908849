import React from 'react';
import { ControllerStateAndHelpers } from 'downshift';
import { useHawkConfig } from 'components/ConfigProvider';
import { Product } from 'models/Autocomplete';
import SearchBoxBase from 'components/ui/SearchBox/SearchBoxBase';
import * as Constants from 'util/Constants';

/**
 * This component is a simple search input box (with autosuggest) that can be placed globally throughout the site.
 * This search box is intended to be used on non-search pages. On search pages, the `SearchBox` component should be
 * used instead.
 */
function GlobalSearchBox() {
	const { config } = useHawkConfig();
	const searchUrl = config.searchPageUrl || Constants.defaultSearchPageUrl;

	function redirectToSearchPage(keywords: string | null) {
		let redirect = `${searchUrl}?keyword=${encodeURIComponent(keywords ?? '')}`;
		if (config.indexName) {
			redirect += '&indexName=' + config.indexName;
		}

		location.assign(redirect);
	}

	function handleSubmit(keywords: string) {
		redirectToSearchPage(keywords);
	}

	// On select view all matches from suggestions list
	function handleViewAllMatches(downshift: ControllerStateAndHelpers<Product>) {
		const { inputValue } = downshift;
		redirectToSearchPage(inputValue);
	}

	return <SearchBoxBase onSubmit={handleSubmit} onViewMatches={handleViewAllMatches} />;
}

export default GlobalSearchBox;
