/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { SearchType } from 'features/tracking/trackers/hawksearch/tracking';
import { Response, Request } from 'models/Search';
import { dispatchRocEvent } from 'shared/hooks/useRocEventHandler';

/**
 * Dispatches hawk search page tracking events based on search request
 */
export function setSearchTracking(request: Partial<Request>, response: Response) {
	const isLandingPage: boolean = !!request.CustomUrl;
	const hasFacetSelections: boolean =
		request.FacetSelections && Object.keys(request.FacetSelections).length > 0 ? true : false;

	const searchType: SearchType =
		!hasFacetSelections && !request.MaxPerPage && !request.PageNo ? SearchType.Initial : SearchType.Refinement;

	if (isLandingPage) {
		dispatchRocEvent('hawk-landing-page-viewed', {});
	} else {
		dispatchRocEvent('hawk-search-tracking', { trackingId: response.TrackingId, searchType: searchType });
	}
}
