/**
 * Returns visitor expiry date for hawksearch visitor id stored in the cookie for the user.
 */
export function getVisitorExpiry() {
	const d = new Date();
	// 1 year
	d.setTime(d.getTime() + 360 * 24 * 60 * 60 * 1000);
	return d.toUTCString();
}

/**
 * Returns visitor expiry date for hawksearch visit id stored in the cookie for the user.
 */
export function getVisitExpiry() {
	const d = new Date();
	// 4 hours
	d.setTime(d.getTime() + 4 * 60 * 60 * 1000);
	return d.toUTCString();
}

/**
 * Creates GUID.
 */
export function createGuid() {
	const s: string[] = [];
	const hexDigits = '0123456789abcdef';
	for (let i = 0; i < 36; i++) {
		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
	}
	s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
	s[19] = hexDigits.substr(((s[19] as any) & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
	s[8] = s[13] = s[18] = s[23] = '-';

	return s.join('');
}

/**
 * Returns the cookie.
 */
export function getCookie(name) {
	const nameEQ = name + '=';
	const ca = document.cookie.split(';');

	for (let i = 0; i < ca.length; i++) {
		let cookie = ca[i];

		while (cookie.charAt(0) == ' ') {
			cookie = cookie.substring(1, cookie.length);
		}

		if (cookie.indexOf(nameEQ) == 0) {
			return cookie.substring(nameEQ.length, cookie.length);
		}
	}
	return null;
}

/**
 * Sets the cookie.
 */
export function setCookie(name: string, value: string, expiry?: string) {
	let expires = '';

	if (expiry) {
		expires = '; expires=' + expiry;
	}

	document.cookie = name + '=' + value + expires + '; path=/';
}
