import { CancelToken } from 'axios';
import { Request as AutocompleteRequest, Response as AutocompleteResponse } from 'models/Autocomplete';
import { Request as CompareItemRequest, Response as CompareDataResponse } from 'models/CompareItems';
import { Request as SearchRequest, Response as SearchResponse } from 'models/Search';
import { HawkSearchConfig } from 'types/HawkSearchConfig';
import * as Constants from 'util/Constants';
import AxiosHelper from '../../../../services/AxiosHelper';
import { Request as RecommendationRequest, Response as RecommendationResponse } from '../models/Recommendation';

class HawkClient {
	private baseUrl: string;
	private searchUrl: string;
	private dashboardUrl: string;
	private autocompleteUrl: string;
	private compareItemsURL: string;
	private recommendationUrl: string;
	private recommendationWidgetUrl: string;
	// #region HCL
	private enableRemovingDashesFromSearchKeyword: boolean;
	// #endregion

	constructor(config: HawkSearchConfig) {
		this.baseUrl = config.apiUrl || Constants.defaultApiUrl;
		this.dashboardUrl = config.dashboardUrl || Constants.defaultDashboardUrl;
		this.searchUrl = config.searchUrl || Constants.defaultSearchUrl;
		this.autocompleteUrl = config.autocompleteUrl || Constants.defaultAutocompleteUrl;
		this.recommendationUrl = config.recommendationUrl || Constants.defaultRecommendationUrl;
		this.recommendationWidgetUrl = config.recommendationWidgetUrl || Constants.recommendationWidgetUrl;
		/* #region Commerce */
		this.compareItemsURL = config.compareItemsURL || Constants.defaultCompareItemsURL;
		/* #endregion */
		/* #region HCL */
		this.enableRemovingDashesFromSearchKeyword =
			config.enableRemovingDashesFromSearchKeyword || Constants.enableRemovingDashesFromSearchKeyword;
		/* #endregion */
	}

	// #region HCL
	public async search(request: SearchRequest, cancellationToken?: CancelToken): Promise<SearchResponse> {
		const result = await AxiosHelper.post<any>(
			'/ajax/hawksearch/search',
			{ request },
			{ cancelToken: cancellationToken },
		);

		return result.data;
	}

	public async autocomplete(
		request: AutocompleteRequest,
		cancellationToken?: CancelToken,
	): Promise<AutocompleteResponse> {
		const result = await AxiosHelper.post<any>(
			'/ajax/hawksearch/autocomplete',
			{ request },
			{ cancelToken: cancellationToken },
		);

		return result.data;
	}

	public async getComparedItems(
		request: CompareItemRequest,
		cancellationToken?: CancelToken,
	): Promise<CompareDataResponse> {
		const result = await AxiosHelper.post<any>(
			'/ajax/hawksearch/compare',
			{ request },
			{ cancelToken: cancellationToken },
		);

		return result.data;
	}

	public async getRecommendationWidgetItems(
		request: RecommendationRequest,
		cancellationToken?: CancelToken,
	): Promise<RecommendationResponse> {
		const result = await AxiosHelper.post<any>(
			'/ajax/hawksearch/recommendation',
			{ request },
			{ cancelToken: cancellationToken },
		);

		return result.data;
	}
	// #endregion
}

export default HawkClient;
