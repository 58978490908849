import React, { useContext, useState, useRef, MouseEvent } from 'react';
import { Facet as FacetModel, Value } from 'models/Facets';
import { useHawkSearch } from 'components/StoreProvider';
import PlusThinSVG from 'components/svg/PlusThinSVG';
import MinusThinSVG from 'components/svg/MinusThinSVG';
import { useTranslation } from 'react-i18next';
import type Tooltip from '@reach/tooltip';
import 'styles/node-modules/@reach/tooltip/styles.css';
import QuestionMarkCircleSVG from 'shared/components/svg/QuestionMarkCircleSVG';
import Spinner from 'shared/components/Spinner';
import classnames from 'classnames';
const LazyTooltip = React.lazy<typeof Tooltip>(() => import('@reach/tooltip'));
const FacetContext = React.createContext({} as FacetContextValue);

export interface FacetProps {
	facet: FacetModel;
	children: React.ReactNode;
	onFacetChange: () => void;
}

interface FacetContextValue {
	/** The facet model returned from the server for this facet. */
	facet: FacetModel;

	/** Returns the state of the parent facet container. */
	state: FacetState;

	/** An actor interface to perform actions for this facet. */
	actor: FacetActor;

	renderer: FacetRenderer;
}

export interface FacetState {
	/**
	 * An array of facet values for this facet. If this facet has a quick lookup search bar, this list
	 * will be filtered by this quick lookup.
	 */
	facetValues: Value[];

	/**
	 * If the facet is configured for filtering, this is the user's entered filter value. Updated on
	 * immediately when the user types.
	 */
	filter: string;

	/** Whether or not this facet has its values truncated. */
	isTruncated: boolean;

	/** Whether or not this facet is collapsed. */
	isCollapsed: boolean;

	/** If filter is enabled, contains the number of facets that are truncated. */
	remainingFacets: number;

	/** The number of digits displayed after the decimal points for numeric facet values. */
	decimalPrecision: number;
}

export interface FacetActor {
	/** Select the given facet value. */
	selectFacet(facetValue: Value | string): void;

	/** Selects and negates the given facet value. */
	negateFacet(facetValue: Value | string): void;

	/** Sets the selected facet values by replacing existing selections for this facet.  */
	setFacets(facetValues: Value[] | string[]): void;

	/** Sets the filter for this facet container. */
	setFilter(filter: string): void;

	/** Sets whether or not this facet is currently being truncated. */
	setTruncated(truncated: boolean): void;

	/** Sets whether or not this facet is currently collapsed. */
	setCollapsed(collapsed: boolean): void;
}

export interface FacetRenderer {
	/**
	 * Conditionally renders the default truncation UI (the "Show More"/"Show Less" buttons). Nothing will render
	 * if the facet is configured to not be truncated.
	 */
	renderTruncation();
}

function Facet({ facet, children, onFacetChange }: FacetProps) {
	const { actor: searchActor } = useHawkSearch();
	const wrapperRef = useRef<HTMLInputElement>(null);
	const [filter, setFilter] = useState('');
	const [isTruncated, setTruncated] = useState(facet.shouldTruncate);
	const [isCollapsed, setCollapsed] = useState(facet.IsCollapsible && facet.IsCollapsedDefault);
	const { t } = useTranslation();

	const hasTooltip = !!facet.Tooltip;

	function selectFacet(facetValue: Value | string): void {
		setFilter('');
		searchActor.toggleFacetValue(facet, facetValue);
		onFacetChange();
	}

	function setFacets(values: Value[] | string[]): void {
		setFilter('');
		searchActor.setFacetValues(facet, values);
		onFacetChange();
	}

	function negateFacet(facetValue: Value | string): void {
		setFilter('');
		searchActor.toggleFacetValue(facet, facetValue, /* negate */ true);
		onFacetChange();
	}

	function renderTruncation() {
		// only show the toggle button if the facet is configured for truncation and we're not filtering

		return (
			<>
				{facet.shouldTruncate && !filter && (
					<button onClick={() => actor.setTruncated(!isTruncated)} className="hawk-facet-rail__show-more-btn">
						{isTruncated ? `(+) Show ${remainingFacets} More` : '(-) Show Less'}
					</button>
				)}
			</>
		);
	}

	// TODO: sort facet values
	let facetValues = facet.Values;

	// first, perform any filtering if enabled and a filter has been typed in
	if (facet.shouldSearch && filter) {
		facetValues = facet.Values.filter((val) => {
			if (!val.Label) {
				// if a facet value doesn't have a label, we can't really filter down to it
				// so exclude it
				return false;
			}

			return val.Label.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
		});
	}

	// next, handle truncation
	let remainingFacets = 0;

	if (facet.shouldTruncate && isTruncated) {
		const valuesBeforeTrunc = facetValues.length;

		facetValues = facetValues.slice(0, facet.TruncateThreshold);

		remainingFacets = valuesBeforeTrunc - facet.TruncateThreshold;
	}

	const actor: FacetActor = {
		selectFacet,
		negateFacet,
		setFacets,
		setFilter,
		setTruncated,
		setCollapsed,
	};

	const state: FacetState = {
		facetValues,
		filter,
		isTruncated,
		isCollapsed,
		remainingFacets,
		decimalPrecision: 2,
	};

	const renderer: FacetRenderer = {
		renderTruncation,
	};

	function toggleCollapsible(event: MouseEvent) {
		if (wrapperRef.current && wrapperRef.current.contains(event.target as Node)) {
			return;
		}
		setCollapsed(!isCollapsed);
	}

	function facetStyle(facet: FacetModel) {
		if (facet.DisplayType === 'scrolling' && facet.ScrollHeight) {
			return {
				height: facet.ScrollHeight,
				overflowY: 'auto',
			} as React.CSSProperties;
		}
		return {};
	}

	return (
		<React.Suspense fallback={<Spinner />}>
			<FacetContext.Provider value={{ facet, state, actor, renderer }}>
				<div
					className={classnames('hawk-facet-rail__facet', {
						'hawk-facet-rail__facet--has-tooltip': hasTooltip,
					})}
				>
					<div className="hawk-facet-rail__facet-heading-wrapper">
						<button
							type="button"
							className="hawk-facet-rail__facet-heading"
							onClick={(event) => toggleCollapsible(event)}
							aria-expanded={!isCollapsed}
							aria-describedby={'hawk-facet-rail-facet-heading-' + facet.Name}
						>
							<h4 className="hawk-facet-rail__facet-heading-name">{facet.Name}</h4>

							{isCollapsed ? (
								<>
									<PlusThinSVG class="hawk-facet-rail__facet-heading-icon" />{' '}
									<span className="roc-visually-hidden">Expand facet category</span>{' '}
								</>
							) : (
								<>
									<MinusThinSVG />{' '}
									<span className="roc-visually-hidden">Collapse facet category</span>
								</>
							)}
						</button>

						{facet.Tooltip ? (
							<LazyTooltip label={facet.Tooltip} as={'div'}>
								<button
									className="hawk-facet-rail__facet-tooltip"
									type="button"
									aria-label={facet.Name + ' tooltip'}
								>
									<QuestionMarkCircleSVG className="hawk-facet-rail__facet-tooltip-icon" />
								</button>
							</LazyTooltip>
						) : null}
					</div>

					<div id={'hawk-facet-rail-facet-heading-' + facet.Name} className="roc-visually-hidden">
						Clicking this button will display a list of tabbable {facet.Name} values below. Tab down to
						interact with them.
					</div>

					{!isCollapsed && (
						<div className="hawk-facet-rail__facet-body" style={facetStyle(facet)}>
							{facet.shouldSearch && (
								<div className="hawk-facet-rail__facet-quick-lookup">
									<input
										value={filter}
										onChange={(e) => setFilter(e.currentTarget.value)}
										type="text"
										placeholder={t('Quick Lookup')}
									/>
								</div>
							)}

							{/* render listing component */}
							{children}
						</div>
					)}
				</div>
			</FacetContext.Provider>
		</React.Suspense>
	);
}

export function useFacet() {
	return useContext(FacetContext);
}

export default Facet;
